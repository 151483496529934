<template>
  <div style="height: 2500px">
     <el-table :data="tableData" v-loading="loading" style="width: 100%"
     border >
      <el-table-column
        prop="playUvNum"
        label="阅读UV">
      </el-table-column>

      <el-table-column
        prop="likeNum"
        label="点赞UV">
      </el-table-column>

       <el-table-column
        prop="followUvNum"
        label="收藏UV">
      </el-table-column>

      <el-table-column
        prop="commentNum"
        label="评论UV">
      </el-table-column>

      <el-table-column
        prop="shareNum"
        label="分享UV">
      </el-table-column>

      <el-table-column
        prop="cateSearchBrowseUv"
        label="搜索浏览UV">
      </el-table-column>

      <el-table-column
        prop="browseUv"
        label="商品浏览UV">
      </el-table-column>

      <el-table-column
        prop="addCartUv"
        label="商品加购UV">
      </el-table-column>

      <el-table-column
        prop="followUv"
        label="商品收藏UV">
      </el-table-column>

      <el-table-column
        prop="orderUv"
        label="下单UV">
      </el-table-column>

      <el-table-column
        prop="orderGmv"
        label="下单GMV">
      </el-table-column>

      <el-table-column
        prop="dealUv"
        label="成交UV">
      </el-table-column>

      <el-table-column
        prop="dealGmv"
        label="成交GMV">
      </el-table-column>

      <el-table-column
        prop="cateNewUserSum"
        label="新客UV">
      </el-table-column>

      <el-table-column
        prop="feedbackRatio"
        label="回传数据比例(%)">
      </el-table-column>

    </el-table>


    <div id="myChart" :style="{width: '100%', height: '380px'}"></div>
<el-button type="primary" @click="downloadAllXhmOrder()">下载任务订单汇总数据</el-button>

     <el-table :data="orderTableData" v-loading="loading" style="width: 100%" border >
      <el-table-column
        prop="orderId"
        label="媒体订单ID">
      </el-table-column>

      <el-table-column
        prop="orderName"
        label="媒体订单名称	">
      </el-table-column>

       <el-table-column
        prop="orderStatusText"
        label="媒体订单状态	">
      </el-table-column>

      <el-table-column
        prop="orderPrice"
        label="媒体订单总金额	">
      </el-table-column>

      <el-table-column
        prop="categoryName"
        label="推广类目	">
      </el-table-column>

      <el-table-column
        prop="playUvNum"
        label="阅读UV	">
      </el-table-column>

      <el-table-column
        prop="likeNum"
        label="点赞UV	">
      </el-table-column>

      <el-table-column
        prop="followUvNum"
        label="收藏UV	">
      </el-table-column>

      <el-table-column
        prop="commentNum"
        label="评论UV	">
      </el-table-column>

      <el-table-column
        prop="shareNum"
        label="分享UV	">
      </el-table-column>

      <el-table-column
        prop="activeUvNum"
        label="活跃UV	">
      </el-table-column>

      <el-table-column
        label="下载数据">
         <template slot-scope="scope">
              <el-button plain class="handel" @click="downloadXhmOrder(scope.row)">订单日期明细数据</el-button>
              </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[20]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>

  
</template>
<script>
import { getXhmDetail, getXhmDaily, getXhmOrder,downloadXhmOrder,downloadAllXhmOrder } from "@/api/xhmdetail";
import * as echarts from 'echarts'

  export default {
    
    data() {
      return {
      taskId: '',
      taskName: '',
      tableData: [],
      orderTableData: [],
      currentPage: 1,
      pageSize: 20,
      total: 0,
      loading: false,
      };
    },
    mounted(){
this.search()
    },
    methods: {
      async downloadXhmOrder(val) {
this.taskId = val.taskId,
this.orderId = val.orderId,
await downloadXhmOrder({taskId: this.taskId, orderId: this.orderId})
          .then((res) => {
            this.loading = false;
            if (res.code == 1000) {
      window.open(res.data)
          }else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      })
      },
      async downloadAllXhmOrder() {
await downloadAllXhmOrder({taskId: this.taskId})
          .then((res) => {
            this.loading = false;
            if (res.code == 1000) {
      window.open(res.data)
          }else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      })
      },
      async getXhmOrder(){
        this.taskId = this.$route.query.taskId;
await getXhmOrder({taskId: this.taskId, pageNo: this.currentPage, pageSize: this.pageSize})
          .then((res) => {
            this.loading = false;
            if (res.code == 1000) {
      this.orderTableData = res.data.result;
      this.total = res.data.totalNum;
          }else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      })},
      async search(){
      this.taskId = this.$route.query.taskId;
      this.taskName = this.$route.query.taskName;
           this.loading = true;
        await getXhmDetail({taskId: this.taskId})
          .then((res) => {
            this.loading = false;
            if (res.code == 1000) {
      this.tableData = res.data;
      // this.total = res.data.total;
          }else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      }),
        await this.getXhmOrder();
       await getXhmDaily({taskId: this.taskId})
          .then((res) => {
            this.loading = false;
            if (res.code == 1000) {
// 提取X轴数据和Y轴数据
let data = res.data
let xData = data.dt;
let yduv = data.videoUvNum;
let dzuv = data.likeNum;
let followUv = data.followUvNum;
let commentUv = data.commentNum;
let searchBrowseUv = data.searchBrowseUv;
let browseUv = data.browseUv
let orderGmv = data.orderGmv;
let orderUv = data.orderUv;
let dealGmv = data.dealGmv;
let dealUv = data.dealUv;
var chartDom = document.getElementById("myChart");
let myChart = echarts.init(chartDom);    
     // 使用ECharts的配置项进行配置
let option = {
  title: {
    text: this.taskName + '-小红盟每日数据'
  },
  tooltip: {
					trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
					axisPointer: {// 坐标轴指示器，坐标轴触发有效
						type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
					}
},
  legend: {
    data: ['阅读UV', '点赞UV', '收藏UV','评论UV','搜索浏览UV','商品浏览UV','下单GMV','下单UV','成交GMV','成交UV'],
    top:"6%"
  },
  xAxis: {
    type: 'category',
    data: xData,
    axisLabel:{
            show:true,  //这里的show用于设置是否显示x轴下的字体 默认为true
　　　　　　　interval:0,  //可以设置成 0 强制显示所有标签。如果设置为 1，表示『隔一个标签显示一个标签』，如果值为 2，表示隔两个标签显示一个标签，以此类推。
　　
　　　　　　　textStyle:{   //textStyle里面写x轴下的字体的样式
               color:'#333',
               fontSize:13
            },
            rotate:45
       },
    axisLine:{
           show:true,  //这里的show用于设置是否显示x轴那一条线 默认为true
           lineStyle:{ //lineStyle里面写x轴那一条线的样式
             color:'#6FC6F3',
             width:2,    //轴线的粗细 我写的是2 最小为0，值为0的时候线隐藏
           }
       }
  },
  yAxis: {
    type: 'value',
       //用于设置y轴的字体
    axisLabel:{
            show:true,  //这里的show用于设置是否显示y轴下的字体 默认为true
            textStyle:{   //textStyle里面写y轴下的字体的样式
               color:'#333',
               fontSize:13
            }
       },
     //用于设置y轴的那一条线
    axisLine:{
           show:true,  //这里的show用于设置是否显示y轴那一条线 默认为true
           lineStyle:{ //lineStyle里面写y轴那一条线的样式
             color:'#6FC6F3',
             width:2,    //轴线的粗细 我写的是2 最小为0，值为0的时候线隐藏
           }
       }
  },
  dataZoom: [{
		show: true,
		endValue: 9//x轴少于10个数据，则显示全部，超过10个数据则显示前10个。
	}],
  series: [
    {
      name: '阅读UV',
      type: 'line',
      data: yduv,
      smooth: true,
    },
    {
      name: '点赞UV',
      type: 'line',
      data: dzuv,
      smooth: true,
    },
    {
      name: '收藏UV',
      type: 'line',
      data: followUv,
      smooth: true,
    },
     {
      name: '评论UV',
      type: 'line',
      data: commentUv,
      smooth: true,
    },
     {
      name: '搜索浏览UV',
      type: 'line',
      data: searchBrowseUv,
      smooth: true,
    },
     {
      name: '商品浏览UV',
      type: 'line',
      data: browseUv,
      smooth: true,
    },
     {
      name: '下单GMV',
      type: 'line',
      data: orderGmv,
      smooth: true,
    },
    {
      name: '下单UV',
      type: 'line',
      data: orderUv,
      smooth: true,
    },
     {
      name: '成交GMV',
      type: 'line',
      data: dealGmv,
      smooth: true,
    },
    {
      name: '成交UV',
      type: 'line',
      data: dealUv,
      smooth: true,
    },
  ]
};
myChart.setOption(option);
          } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getXhmOrder(); // 重新搜索以更新分页数据
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getXhmOrder(); // 重新搜索以更新分页数据
    }
    }
  };
</script>

